import { ref, onMounted, computed } from '@vue/composition-api'
import GSTC from 'gantt-schedule-timeline-calendar/dist/gstc.wasm.esm.min.js'

import { avatarText } from '@core/utils/filter'
import { stringToHslColor} from '@core/utils'

import useProjectView from '@/views/apps/project/project-view/useProjectView'
import useProject from '@/views/apps/project/useProject'

export default function useTimeline() {

    const {
      resolveCountryStatusVariant
    } = useProjectView()

    const { 
      
      statusLoading,
      statusOptions,
      statusSearch,
      countryLoading,
      countryOptions,
      countrySearch,
      projTypeLoading,
      projTypeOptions,
      projTypeSearch,
      researchMetLoading,
      researchMetOptions,
      researchMetSearch,
      
      fetchStatuses,
      fetchCountries,
      fetchProjectTypes,
      fetchResearchMethods,

    } = useProject()

    const taskOptions = ref([])
    const taskLoading = ref(false)
    const taskSearch = ref('')

    const groupLoading = ref(false)
    const groupOptions = ref([])
    const groupSearch = ref('')

    const officeLoading = ref(false)
    const officeOptions = ref([])
    const officeSearch = ref('')

    const colors = ['#E74C3C', '#DA3C78', '#7E349D', '#0077C0', '#07ABA0', '#0EAC51', '#F1892D'];
    const gstc = ref({})
    const state = ref({})

    
    const startDate = ref({})
    
    const endDate = ref({})
    const GSTCID = GSTC.api.GSTCID;

    const activeTimelineView = ref({ label: 'Month', value: 'month', jump: 0, option: 'month'})
    const timelineViewOptions = [
      { label: 'Day', value: 'day', jump: 0, option: 'day'},
      { label: 'Week', value: 'week', jump: 0, option: 'week'},
      { label: '2-Week', value: 'week', jump: 1, option: 'week'},
      { label: 'Month', value: 'month', jump: 0, option: 'month'},
      { label: '2-Month', value: 'month', jump: 1, option: 'month'},
      { label: '3-Month', value: 'month', jump: 2, option: 'month'},
    ]

    const activeSortView = ref({label:'Name/Label Asc', value:'label_asc', option:'label', asc:true})
    const sortViewOptions = [
      {label:'Name/Label Asc', value:'label_asc', option:'label', asc:true},
      {label:'Name/Label Desc', value:'label_desc', option:'label', asc:false},
      {label:'Status Asc', value:'status_asc', option:'status', asc:true},
      {label:'Status Desc', value:'status_desc', option:'status', asc:false},
      {label:'Report Asc', value:'report_asc', option:'report', asc:true},
      {label:'Report Desc', value:'report_desc', option:'report', asc:false},
      {label:'Field Start Asc', value:'field_asc', option:'field', asc:true},
      {label:'Field Start Desc', value:'field_desc', option:'field', asc:false},
    ]

    const activeStatusView = ref(['PRE', 'IN', 'POST'])
    const activeCountriesView = ref([])
    const activeResearchMethView = ref([])
    const activeProjTypeView = ref([])
    const months = [
        {
          zoomTo: 17,
          period: 'day',
          periodIncrement: 1,
          classNames: ['gstc-date-medium gstc-date-left'],
          format({ timeStart }) {
            return timeStart.format('DD MMMM YYYY (dddd)');
          },
        },
        {
          zoomTo: 25,
          period: 'month',
          periodIncrement: 1,
          format({ timeStart }) {
            return timeStart.format('MMMM YYYY');
          },
        },
        {
          zoomTo: 100,
          period: 'month',
          periodIncrement: 1,
          format({ timeStart }) {
            return timeStart.format('MMM YY');
          },
        }
    ]
    const weeks = [
        {
          zoomTo: 23,
          period: 'week',
          periodIncrement: 1,
          format({ timeStart, vido, className }) {
            return vido.html`<div class="${className}-content gstc-date-small ">Week ${getWeek(new Date(timeStart.$d))}</div>`;
          },
        },
        {
          zoomTo: 100,
          period: 'week',
          periodIncrement: 1,
          format({ timeStart, vido, className }) {
            return vido.html`<div class="${className}-content gstc-date-small">Wk ${getWeek(new Date(timeStart.$d))}</div>`;
          },
        },
    ]
    const days = [
        {
          zoomTo: 20,
          period: 'day',
          main: true,
          periodIncrement: 1,
          classNames: ['gstc-date-vertical'],
          format({ timeStart, vido, className }) {
            return vido.html`<div class="${className}-content gstc-date-top">${timeStart.format(
              'DD'
            )}</div><div class="${className}-content gstc-date-small">${timeStart.format(
              'dddd'
            )}</div>`;
          },
        },
        {
          zoomTo: 22,
          period: 'day',
          main: true,
          periodIncrement: 1,
          classNames: ['gstc-date-vertical'],
          format({ timeStart, vido, className }) {
            return vido.html`<div class="${className}-content gstc-date-top">${timeStart.format(
              'DD'
            )}</div><div class="${className}-content gstc-date-small">${timeStart.format(
              'ddd'
            )}</div>`;
          },
        },
        {
          zoomTo: 23,
          period: 'day',
          main: true,
          periodIncrement: 1,
          classNames: ['gstc-date-vertical'],
          format({ timeStart, className, vido }) {
            return vido.html`<div class="${className}-content gstc-date-top gstc-date-extra-small">${timeStart.format(
              'D'
            )}</div><div class="${className}-content gstc-date-extra-small">${timeStart.format(
              'dd'
            )}</div>`;
          },
        },
        {
          zoomTo: 25,
          period: 'day',
          main: true,
          periodIncrement: 1,
          classNames: ['gstc-date-vertical'],
          format({ timeStart, className, vido }) {
            return vido.html`<div class="${className}-content gstc-date-top gstc-date-extra-small">${timeStart.format(
              'D'
            )}</div>`;
          },
        },
        {
          zoomTo: 100,
          period: 'day',
          main: true,
          periodIncrement: 1,
          classNames: ['gstc-date-vertical'],
          format({ timeStart, className, vido }) {
            return vido.html`<div class="${className}-content gstc-date-top gstc-date-extra-small">${timeStart.format(
              'D'
            )}</div>`;
          },
        }      
        
    ]

    const columns = {
      data: {
          [GSTCID('label')]: {
            id: GSTCID('label'),
            data: 'label',
            sortable: ({row}) => { 
              return row.name
            },
            isHTML: false,
            expander: true,
            width: 235,
            header: {
              content: 'Overview',
            },
          },
          [GSTCID('status')]: {
            id: GSTCID('job_status'),
            data: ({ row, vido }) => {
              return vido.html`<div class="${resolveCountryStatusVariant(row.job_status ? row.job_status.code : 'PRE')}" style="text-align: center; color: #FFF; min-width: 50px">${row.job_status ? row.job_status.code : ''}</div>`;
            },
            width: 80,
            sortable: ({row}) => { 
              if (row.job_status){
                return `${row.job_status.name}`
              }
              return ''
            },
            header: {
              content: 'Status',
            },
          },
          [GSTCID('countries')]: {
            id: GSTCID('field_countries'),
            data: ({ row, vido }) => {
              return vido.html`<div style="text-align: left; min-width: 80px">${row.field_countries_code ? row.field_countries_code : ''}</div>`;
            },
            width: 80,
            header: {
              content: 'Countries',
            },
          },
          [GSTCID('report')]: {
            id: GSTCID('report'),
            data: 'report_date',
            width: 80,
            sortable: 'report_date',
            hidden:true,
            header: {
              content: 'Report',
            },
          },
          [GSTCID('field')]: {
            id: GSTCID('field'),
            data: 'field_start',
            width: 80,
            sortable: 'field_start',
            hidden:true,
            header: {
              content: 'Field Start',
            },
          }
      },
  };


    const getWeek = (date) => {
        var janOne = new Date(date.getFullYear(), 0, 2);
        return Math.ceil((((date - janOne) / 86400000) + date.getDay() + 1) / 7);
    }

    

    const getRandomColor = () => {
        return colors[Math.floor(Math.random() * colors.length)];
    }

    const timelineFilter = computed({
        get: () => JSON.parse(localStorage.getItem('timeline-filter')),
        set: val => {
          let filter = JSON.parse(localStorage.getItem('timeline-filter'))
          
          localStorage.setItem('timeline-filter', JSON.stringify({...filter, ...val}))
        },
    })

    
    const statusFilter = ref(null)

    const normalizeProjectTasksToGtsc = (rows, items, project, azure_ad_id=null, disabled=false) => {
      
      const userId = azure_ad_id ? GSTCID(azure_ad_id) : null
      const id = azure_ad_id ? GSTCID(`${azure_ad_id}-${project.job_number}`) : GSTCID(`${project.job_number}`) 
      const rowId = id
      const tasks = project.project_tasks
      delete project.project_tasks
      rows[id] = {
        id,
        label: `${project.job_number} - ${project.job_name}`,
        name: project.job_number,
        project: project.job_number,
        tasks: [],
        azure_ad_id,
        parentId:userId,
        expanded: false,
        disabled: !project.editable ? !project.editable : disabled,
        class: 'project',
        color: stringToHslColor(project.job_name),
        // avatar: project.job_number,
        ...project
      };

      tasks.forEach(task => {
        const gstcTaskId = generateTaskID(task.id, 's', azure_ad_id);
        const convertedTask = convertTaskObjToGSTCObj(task, gstcTaskId, 'study', rowId, project.job_number)
        rows[id].tasks.push(convertGSTCObjToTaskObj(convertedTask))
        items[gstcTaskId] = convertedTask
      })
    }

    const normalizeToGtsc = (user, personalTasks, projects, disabled=false) => {
      const newId = GSTCID(user.azure_ad_id)
      const rows = {}
      const items = {}

      rows[newId] =  {
        id: newId,
        name: user.name,
        label: user.name,
        class: 'user',
        expanded: true,
        report_date:'',
        field_start:'',
        disabled,
        azure_ad_id: user.azure_ad_id,
        color: stringToHslColor(user.name),
        avatar: avatarText(user.name),
        supplier: user.supplier ? user.supplier : null
      }

      projects.forEach(project => {
        normalizeProjectTasksToGtsc(rows,items, project, user.azure_ad_id, disabled)
        
        
      })
      personalTasks.forEach(task => {
        const gstcTaskId = generateTaskID(task.id, 'p', user.azure_ad_id);
        items[gstcTaskId] = convertTaskObjToGSTCObj(task, gstcTaskId, 'personal', newId)
      })

      return {
        rows,
        items
      }

    }

    const generateTaskID = (taskId, type, userID=null) => {
      if (userID) return GSTCID(`${userID}-${type}-${taskId}`);
      else return GSTCID(`${type}-${taskId}`);
    }

    const generateGSTCID = (id) => {
      return GSTCID(id);
    }

    const convertGSTCObjToTaskObj = (gstcTask) => {
      const start = GSTC.api
          .date(gstcTask.time.start)
          .valueOf();
      const end = GSTC.api
          .date(gstcTask.time.end)
          .startOf('day')
          .valueOf();
      const calendarStart = GSTC.api
          .date(gstcTask.calendar ? gstcTask.calendar.start : GSTC.api.date())
          .startOf('day')
          .valueOf();
      return {
          id: gstcTask.taskId,
          name: gstcTask.label,
          start,
          end,
          calendar: {
            start: calendarStart
          },
          class: gstcTask.class,
          project: gstcTask.project,
          type: gstcTask.type,
          fill: gstcTask.fill,
          color: gstcTask.color,
          percent_complete: gstcTask.progress,
          desc: gstcTask.desc,
          bucket: gstcTask.bucket,
          assignments: gstcTask.assignments
      }
  }

  const convertTaskObjToGSTCObj = (task, key, model, rowId=null, project=null) => {

    const start = GSTC.api.date(task.start_date_time.substring(0,10))
    const end = GSTC.api.date(task.due_date_time.substring(0,10)).endOf('day')
    return {
      id: key,
      taskId: task.id,
      label: task.name,
      type: task.name.toLowerCase().includes('field work') ? 'milestone' : 'task',  
      progress: task.percent_complete,
      time: {
        start,
        end,
      },
      rowId,
      project,
      class: project ? 'project' : 'user',
      assignments: task.assignments,
      color: task.color,
      eventTextColor: task.eventTextColor,
      fill: task.fill,
      bucket: task.bucket,
      owner: task.owner,
      desc: task.desc,
      model
    }
  }



  const init = () => {

    var timeline = null
    if (timelineFilter.value) {
      if (timelineFilter.value.timeline) activeTimelineView.value = timelineFilter.value.timeline
      if (timelineFilter.value.sort) activeSortView.value = timelineFilter.value.sort
      if (timelineFilter.value.status) activeStatusView.value = timelineFilter.value.status
      if (timelineFilter.value.countries) activeCountriesView.value = timelineFilter.value.countries
      if (timelineFilter.value.researchMethods) activeResearchMethView.value = timelineFilter.value.researchMethods
      if (timelineFilter.value.projectTypes) activeProjTypeView.value = timelineFilter.value.projectTypes
      timeline = timelineFilter.value.timeline
    
    } else {
      timelineFilter.value = {
        timeline: activeTimelineView.value,
        sort: activeSortView.value,
        status: activeStatusView.value,
        countries: activeCountriesView.value,
        researchMethods: activeResearchMethView.value,
        projectTypes: activeProjTypeView.value
      }
      
    }

    if (!timeline) timeline = activeTimelineView.value
    
    startDate.value = GSTC.api.date().startOf(timeline.value)
    endDate.value = startDate.value.clone()
      .add(timeline.jump, timeline.value)
      .endOf(timeline.value)

  }

  init()
  

  return {
        months,
        weeks,
        days,
        gstc,
        state,
        startDate,
        endDate,
        activeTimelineView,
        timelineViewOptions,
        activeSortView,
        activeStatusView,
        activeCountriesView,
        activeResearchMethView,
        activeProjTypeView,
        sortViewOptions,
        timelineFilter,
        statusOptions,
        statusFilter,
        statusLoading,
        statusSearch,
        countryLoading,
        countryOptions,
        countrySearch,
        projTypeLoading,
        projTypeOptions,
        projTypeSearch,
        researchMetLoading,
        researchMetOptions,
        researchMetSearch,
        taskOptions,
        taskLoading,
        taskSearch,
        columns,
        groupLoading,
        groupOptions,
        groupSearch,
        officeLoading,
        officeOptions,
        officeSearch,

        getRandomColor,
        normalizeToGtsc,
        normalizeProjectTasksToGtsc,
        convertTaskObjToGSTCObj,
        convertGSTCObjToTaskObj,
        generateTaskID,
        generateGSTCID,
        resolveCountryStatusVariant,
        fetchStatuses,
        fetchCountries,
        fetchProjectTypes,
        fetchResearchMethods
    }
}