<template>
  <v-card class="max-h-content-container app-calendar position-relative overflow-hidden text-sm">


    <v-navigation-drawer
      v-model="isEventHandlerDrawerActive"
      :right="!$vuetify.rtl"
      touchless
      app
      temporary
      width="450"
    >
      <timeline-event-handler-drawer-content
        :task="event"
        :clear-event-data="clearEventData"
        :taskOptions="taskOptions"
        :taskLoading="taskLoading"
        @remove-task="removeTask"
        @add-task="addTask"
        @update-task="updateTask"
        @close-drawer="isEventHandlerDrawerActive = false"
      ></timeline-event-handler-drawer-content>
    </v-navigation-drawer>
    
      <div
        v-if="refTimeline"
        class="calendar-header mx-6 my-5 align-center flex-wrap"
      >
        <v-row
          class="pt-2 pl-2">
        <v-btn
            outlined
            class="mr-4"
            color="grey darken-2"
            @click="today()"
        >
            {{ t('Today') }}
        </v-btn>
        <v-btn
          icon
          class="d-inline-block d-md-none me-1"
          @click="isLeftSidebarOpen = true"
        >
          <v-icon size="30">
            {{ icons.mdiMenu }}
          </v-icon>
        </v-btn>
        <v-btn
          icon
          class="me-1"
          @click="prev()"
        >
          <v-icon size="30">
            {{ icons.mdiChevronLeft }}
          </v-icon>
        </v-btn>
        <v-btn
          icon
          class="me-3"
          @click="next()"
        >
          <v-icon size="30">
            {{ icons.mdiChevronRight }}
          </v-icon>
        </v-btn>
        <p class="font-weight-semibold text-xl text--primary mb-0 me-6">
          {{ refTimeline.title }}
        </p>

        <v-spacer></v-spacer>
        
        <v-checkbox
          v-model="displayUserTasks"
          hide-details
          label="Show user's tasks only"
          @change="filterUserTasks"
          class=" align-self-center mt-0"
          v-if="groupView || userView"
        ></v-checkbox>
        <v-menu
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                outlined
                color="grey darken-2"
                v-bind="attrs"
                v-on="on"
                class="mx-4"
            >
                <span>{{ activeTimelineView.label }}</span>
                <v-icon right>
                  {{ icons.mdiMenuDown }}
                </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item 
              v-for="timelineViewOption, index in timelineViewOptions" 
              :key="index"
              @click="onActiveTimelineViewChange(timelineViewOption)"
            >
              <v-list-item-title>{{ timelineViewOption.label }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                outlined
                color="grey darken-2"
                v-bind="attrs"
                v-on="on"
                class="mr-4"
            >
                <span>{{ activeSortView.label }}</span>
                <v-icon right>
                  {{ icons.mdiMenuDown }}
                </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item 
              v-for="sortViewOption, index in sortViewOptions" 
              :key="index"
              @click="onActiveSortViewChange(sortViewOption)"
            >
              <v-list-item-title>{{ sortViewOption.label }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        </v-row>
        <v-row class="d-flex pt-0 justify-end">
          <v-col
            md="4"
            class="pb-0"
            v-if="fieldView"
          >
            <v-autocomplete
              v-model="activeCountriesView"
              placeholder="Select Country"
              label="Country"
              :loading="countryLoading"
              :items="countryOptions"
              :search-input.sync="countrySearch"
              item-text="label"
              item-value="value"
              multiple
              outlined
              dense
              small-chips
              hide-details
              deletable-chips
              @change="countrySearch=''"
              @input="onActiveCountriesViewChange($event)"
            >
            </v-autocomplete>
          </v-col>
          <v-col
            md="4"
            class="pb-0"
            v-if="fieldView"
          >
            <v-autocomplete
              v-model="activeResearchMethView"
              placeholder="Select Research Method"
              label="Research Method"
              :loading="researchMetLoading"
              :items="researchMetOptions"
              :search-input.sync="researchMetSearch"
              item-text="label"
              item-value="value"
              multiple
              outlined
              dense
              small-chips
              hide-details
              deletable-chips
              @change="researchMetSearch=''"
              @input="onActiveResearchMethodsViewChange($event)"
            >
            </v-autocomplete>
          </v-col>
          <v-col
            md="4"
            class="pb-0"
            v-if="fieldView"
          >
            <v-autocomplete
              v-model="activeProjTypeView"
              placeholder="Select Project Type"
              label="Project Type"
              :loading="projTypeLoading"
              :items="projTypeOptions"
              :search-input.sync="projTypeSearch"
              item-text="label"
              item-value="value"
              multiple
              outlined
              dense
              small-chips
              hide-details
              deletable-chips
              @change="projTypeSearch=''"
              @input="onActiveProjectTypesViewChange($event)"
            >
            </v-autocomplete>
          </v-col>
          <v-col
            md="6"
            class="pb-0"
              v-if="groupView"
          >
            <v-autocomplete
              v-model="groupView"
              placeholder="Select Team"
              label="Team"
              :loading="groupLoading"
              :items="groupOptions"
              :search-input.sync="groupSearch"
              item-text="label"
              item-value="value"
              outlined
              dense
              small-chips
              hide-details
              @change="$router.push({name:'team-timeline',params:{id:groupView}})"
            >
            </v-autocomplete>
          </v-col>
          <v-col
            md="6"
            class="pb-0"
              v-if="officeView"
          >
            <v-autocomplete
              v-model="officeView"
              placeholder="Select Office"
              label="Office"
              :loading="officeLoading"
              :items="officeOptions"
              :search-input.sync="officeSearch"
              item-text="label"
              item-value="value"
              outlined
              dense
              small-chips
              hide-details
              @change="$router.push({name:'office-timeline',params:{id:officeView}})"
            >
            </v-autocomplete>
          </v-col>
          <v-col 
            md="6"
            class="pb-0"
          >
          <v-autocomplete
              v-model="activeStatusView"
              placeholder="Select Statuses"
              label="Statuses"
              :loading="statusLoading"
              :items="statusOptions"
              :search-input.sync="statusSearch"
              item-text="label"
              item-value="value"
              cache-items
              outlined
              dense
              hide-details
              multiple
              small-chips
              deletable-chips
              @change="statusSearch=''"
              @input="onActiveStatusViewChange($event)"
            >
              <template #selection="{ item }">
                <v-chip :color="`${resolveCountryStatusVariant(item.value)}`" small>{{item.value}}</v-chip>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </div>
      
      <v-col
        cols="12"
        md="12"
      >
        <timeline
          v-if="isLoaded"
          :timelineEvents="calendarEvents"
          :filterUserTasks="displayUserTasks"
          @click:day="timelineHandleTaskClick"
          @click:task="timelineHandleTaskClick"
          @click:event="fetchUserTasks"
          @click:project-detail="openProjectDetailDialog"
          ref="refTimeline"
        ></timeline>
      </v-col>
      
    <timeline-project-detail
      :isProjectDialogOpen.sync="isProjectDialogOpen"
      :projectData="localProject"
      @update-task="updateTask"
    >
    </timeline-project-detail>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { ref, onUnmounted, watch, getCurrentInstance, onMounted } from '@vue/composition-api'
import { mdiChevronLeft, mdiChevronRight, mdiMenu, mdiMenuDown  } from '@mdi/js'
import router from '@/router'
import store from '@/store'
import { hexToRgb, getVuetify } from '@core/utils'
import timelineStoreModule from './timelineStoreModule'
import useProjectView from '@/views/apps/project/project-view/useProjectView'
import projectStoreModule from '@/views/apps/project/projectStoreModule'

// Local Components
import TimelineEventHandlerDrawerContent from './TimelineEventHandlerDrawerContent.vue'
import TimelineProjectDetail from './TimelineProjectDetail.vue'
import Timeline from './Timeline.vue'
import useTimeline from './useTimeline'

import { foundryRequest } from '@/config/authConfig';

import { formatTasks, formatOptions } from '@core/utils'

import { useUtils } from '@core/libs/i18n'
import GSTC from 'gantt-schedule-timeline-calendar/dist/gstc.wasm.esm.min.js'

export default {
  components: {
    TimelineEventHandlerDrawerContent,
    Timeline,
    TimelineProjectDetail
  },
  props: {
    params: {
      type: Object,
      required: false,
    }
  },
  setup(props) {
    const vm = getCurrentInstance().proxy
    const isLoaded = ref(false)
    const groupView = ref(null)
    const userView = ref(null)
    const fieldView = ref(null)
    const officeView = ref(null)


    const displayUserTasks = ref(false)

    const {
            activeTimelineView,
            timelineViewOptions,
            activeSortView,
            activeStatusView,
            activeCountriesView,
            activeResearchMethView,
            activeProjTypeView,
            sortViewOptions,
            statusOptions,
            statusLoading,
            statusSearch,
            taskOptions,
            taskLoading,
            startDate,
            endDate,
            groupLoading,
            groupOptions,
            groupSearch,
            officeLoading,
            officeOptions,
            officeSearch,
            countryLoading,
            countryOptions,
            countrySearch,
            projTypeLoading,
            projTypeOptions,
            projTypeSearch,
            researchMetLoading,
            researchMetOptions,
            researchMetSearch,

            timelineFilter,
            normalizeToGtsc,
            normalizeProjectTasksToGtsc,
            convertTaskObjToGSTCObj,
            convertGSTCObjToTaskObj,
            generateTaskID,
            generateGSTCID,
            fetchStatuses,
            fetchCountries,
            fetchProjectTypes,
            fetchResearchMethods

        } = useTimeline()

    // ————————————————————————————————————
    //* ——— Store Registration
    // ————————————————————————————————————

    const USER_CALENDAR_APP_STORE_MODULE_NAME = 'app-timeline'
    const USER_PROJECT_APP_STORE_MODULE_NAME = 'app-project'

    // Register module
    if (!store.hasModule(USER_CALENDAR_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_CALENDAR_APP_STORE_MODULE_NAME, timelineStoreModule)
    }
    if (!store.hasModule(USER_PROJECT_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_PROJECT_APP_STORE_MODULE_NAME, projectStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_CALENDAR_APP_STORE_MODULE_NAME)
      if (store.hasModule(USER_PROJECT_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_PROJECT_APP_STORE_MODULE_NAME)
    })

    
    const {
      resolveCountryStatusVariant
    } = useProjectView()


    const fetchProjectTasks = async () => {
      const token = await vm.$msal.getTokenPopup(foundryRequest)
      taskLoading.value = true
      store
        .dispatch(`${USER_PROJECT_APP_STORE_MODULE_NAME}/fetchProjectTasks`)
        .then(response => {
          taskOptions.value = formatTasks(response.data)

        })
        .catch(error => {
          store.commit('comp-alert/SET_MESSAGE', error.response.data)
        })
      
      taskLoading.value = false
    }

    const fetchPersonalBuckets = async () => {
      const token = await vm.$msal.getTokenPopup(foundryRequest)
      store
        .dispatch(`${USER_CALENDAR_APP_STORE_MODULE_NAME}/fetchPersonalBuckets`)
        .then(response => {
          store.commit(`app-timeline/SET_CALENDARS`, response.data)
          fetchProjectTasks()
          fetchUserTasks()
        })
        .catch(error => {
          store.commit('comp-alert/SET_MESSAGE', error.response.data)
        })
    }

    // ————————————————————————————————————
    //* ——— Vuetify Instance
    // ————————————————————————————————————

    const $vuetify = getVuetify()

    

    // I18n
    const { t } = useUtils()


    // ————————————————————————————————————
    //* ——— Templare Ref
    // ————————————————————————————————————

    const refTimeline = ref(null)

    // ————————————————————————————————————
    //* ——— Calendar View/Type
    // ————————————————————————————————————

    // ————————————————————————————————————
    //* ——— Calendar Value & Events
    // ————————————————————————————————————

    const calendarValue = ref()
    const calendarEvents = ref([])
    const timelineView = ref({})

    const filterUserTasks = (evt) => {
      refTimeline.value.filterItems(evt)
      // console.log(evt)
    }

    timelineView.value = timelineFilter.value ? timelineFilter.value.timeline : activeTimelineView

    const blankEvent = {
      id: '',
      name: '',
      start: '',
      end: '',
      bucket: '',
      color: '',
      assignments: [],
      desc: ''
    }

    const event = ref(JSON.parse(JSON.stringify(blankEvent)))
    const clearEventData = () => {
      event.value = JSON.parse(JSON.stringify(blankEvent))
    }

    const fetchUserTasks = async (startTime, endTime) => {
      store.commit('app/LOADING', true)
      
      if ((startTime === undefined || startTime === null) || (endTime === undefined || endTime === null)){
        if (refTimeline.value)
        {
          startTime = GSTC.api.date(refTimeline.value.startTime).format('YYYY-MM-DD')
          endTime = GSTC.api.date(refTimeline.value.endTime).format('YYYY-MM-DD')
        } else {
          startTime = startDate.value.format('YYYY-MM-DD')
          endTime = endDate.value.format('YYYY-MM-DD')
        }
        
        // startTime = startDate.value.format('YYYY-MM-DD')
      } else {
        startTime = GSTC.api.date(startTime).format('YYYY-MM-DD')
        endTime = GSTC.api.date(endTime).format('YYYY-MM-DD')
      }

      
      const statuses = ref('')
      if (activeStatusView){
        statuses.value = activeStatusView.value.join(',')
      }
      const countries = ref('')
      if (activeCountriesView){
        countries.value = activeCountriesView.value.join(',')
      }
      const researchMethods = ref('')
      if (activeResearchMethView){
        researchMethods.value = activeResearchMethView.value.join(',')
      }
      const projectTypes = ref('')
      if (activeProjTypeView){
        projectTypes.value = activeProjTypeView.value.join(',')
      }

      const token = await vm.$msal.getTokenPopup(foundryRequest)
      
      if (props.params){
        if(router.currentRoute.name == 'user-timeline') userView.value = props.params.id
        else userView.value = null
        if(router.currentRoute.name == 'team-timeline') groupView.value = parseInt(props.params.id)
        else groupView.value = null
        if(router.currentRoute.name == 'office-timeline') officeView.value = parseInt(props.params.id)
        else officeView.value = null
      
      } else {
        userView.value = null
        groupView.value = null
        officeView.value = null
      }
      fieldView.value = false
      if (router.currentRoute.name === 'field-timeline') fieldView.value = true


      switch (router.currentRoute.name) {
        case 'user-timeline':
          fetchUser(startTime, endTime, statuses)
          break;
        case 'team-timeline':
          fetchTeam(startTime, endTime, statuses)
          break;
        case 'project-timeline':
          fetchProject(startTime, endTime)
          break;
        case 'field-timeline':
          fieldView.value = true
          fetchField(startTime, endTime, statuses, countries, researchMethods, projectTypes)
          break;
        case 'office-timeline':
          fetchOffice(props.params.id, startTime, endTime, statuses)
          break;
        default:
          break;
      }
    }

    const fetchField = (startTime, endTime, statuses, countries, researchMethods, projectTypes) => {
      const query = router.currentRoute.query
      const field_locations = query['field_locations'] || ''
      store
        .dispatch(`${USER_CALENDAR_APP_STORE_MODULE_NAME}/fetchFieldsTasks`, {
          startTime,
          endTime,
          statuses: statuses.value,
          countries: countries.value,
          research: researchMethods.value,
          project_type: projectTypes.value,
          field_locations
        }).then(response => {
          const fields = response.data
          
          calendarEvents.value = {
            rows: {},
            items: {}
          }

          fields.forEach((field) => {
            const {rows, items} = generateUserTasks(field, true)

            calendarEvents.value.rows = {
              ...calendarEvents.value.rows,
              ...rows
            }
            calendarEvents.value.items = {
              ...calendarEvents.value.items,
              ...items
            }
          })
          
          
          isLoaded.value = true
          //Close drawer
          isEventHandlerDrawerActive.value = false
          store.commit('app/LOADING', false)
        })
        .catch(error => {
          store.commit('app/LOADING', false)
          console.log(error)
          if (error.response){
            store.commit('comp-alert/SET_MESSAGE', error.response.data)
          } else{
            store.commit('comp-alert/SET_MESSAGE', error)
          }
        })
    }

    const fetchOffice = (id, startTime, endTime, statuses) => {

      const query = router.currentRoute.query
      store
        .dispatch(`${USER_CALENDAR_APP_STORE_MODULE_NAME}/fetchOfficeTasks`, {
          id,
          startTime,
          endTime,
          statuses: statuses.value
        }).then(response => {
          const office = response.data
          
          calendarEvents.value = {
            rows: {},
            items: {}
          }

          const {rows, items} = generateUserTasks(office)
          
          calendarEvents.value.rows = {
            ...calendarEvents.value.rows,
            ...rows
          }
          calendarEvents.value.items = {
            ...calendarEvents.value.items,
            ...items
          }
          
          
          isLoaded.value = true
          //Close drawer
          isEventHandlerDrawerActive.value = false
          store.commit('app/LOADING', false)
        })
        .catch(error => {
          store.commit('app/LOADING', false)
          console.log(error)
          if (error.response){
            store.commit('comp-alert/SET_MESSAGE', error.response.data)
          } else{
            store.commit('comp-alert/SET_MESSAGE', error)
          }
        })
    }


    const fetchProject = (startTime, endTime) => {
      store
        .dispatch(`${USER_CALENDAR_APP_STORE_MODULE_NAME}/fetchProject`, {
          startTime,
          endTime,
          id: props.params.id
        })
        .then(response => {
          const { data } = response
          
          calendarEvents.value = {
            rows: {},
            items: {}
          }
          const rows = {}
          const items = {}
        
          generateProjectTasks(data)
      
          normalizeProjectTasksToGtsc(rows, items, data)

          calendarEvents.value.rows = {
            ...calendarEvents.value.rows,
            ...rows
          }
          calendarEvents.value.items = {
            ...calendarEvents.value.items,
            ...items
          }

          
          
          isLoaded.value = true
          //Close drawer
          isEventHandlerDrawerActive.value = false
          store.commit('app/LOADING', false)
          
        })
        .catch(error => {
          store.commit('app/LOADING', false)
          console.log(error)
          if (error.response){
            store.commit('comp-alert/SET_MESSAGE', error.response.data)
          } else{
            store.commit('comp-alert/SET_MESSAGE', error)
          }
        })
      
    }

    const fetchUser = (startTime, endTime, statuses) => {
      store
        .dispatch(`${USER_CALENDAR_APP_STORE_MODULE_NAME}/fetchUserTasks`, {
          personalbuckets: store.state[USER_CALENDAR_APP_STORE_MODULE_NAME].selectedCalendars.join(','),
          startTime,
          endTime,
          statuses: statuses.value,
          email: props.params.id
        })
        .then(response => {

          const user = response.data
          
          calendarEvents.value = {
            rows: {},
            items: {}
          }

          const {rows, items} = generateUserTasks(user)
          
          calendarEvents.value.rows = {
            ...calendarEvents.value.rows,
            ...rows
          }
          calendarEvents.value.items = {
            ...calendarEvents.value.items,
            ...items
          }
          
          
          isLoaded.value = true
          //Close drawer
          isEventHandlerDrawerActive.value = false
          store.commit('app/LOADING', false)
          
        })
        .catch(error => {
          store.commit('app/LOADING', false)
          console.log(error)
          if (error.response){
            store.commit('comp-alert/SET_MESSAGE', error.response.data)
          } else{
            store.commit('comp-alert/SET_MESSAGE', error)
          }
        })
    }

    const fetchTeam = (startTime, endTime, statuses) => {
      store
        .dispatch(`${USER_CALENDAR_APP_STORE_MODULE_NAME}/fetchUsersTasks`, {
          personalbuckets: store.state[USER_CALENDAR_APP_STORE_MODULE_NAME].selectedCalendars.join(','),
          startTime,
          endTime,
          statuses: statuses.value,
          teams: props.params.id
        })
        .then(response => {

          const users = response.data
          calendarEvents.value = {
            rows: {},
            items: {}
          }

          users.forEach((user) => {
            const {rows, items} = generateUserTasks(user)

            calendarEvents.value.rows = {
              ...calendarEvents.value.rows,
              ...rows
            }
            calendarEvents.value.items = {
              ...calendarEvents.value.items,
              ...items
            }
          })
          
          // calendarEvents.value = {
          //   rows,
          //   items
          // }
          
          isLoaded.value = true
          //Close drawer
          isEventHandlerDrawerActive.value = false
          
          store.commit('app/LOADING', false)
        })
        .catch(error => {
          console.log(error)
          store.commit('app/LOADING', false)
          if (error.response){
            store.commit('comp-alert/SET_MESSAGE', error.response.data)
          } else{
            store.commit('comp-alert/SET_MESSAGE', error)
          }
        })
    }

    const generateProjectTasks = (project, azure_ad_id=null) => {
      return project.project_tasks.forEach(task => {
          task.start = GSTC.api.date(task.start_date_time).format('YYYY-MM-DD')
          task.end = GSTC.api.date(task.due_date_time).format('YYYY-MM-DD')
          task.bucket = task.bucket
          task.owner = azure_ad_id

          const eventColor = task.color

          if (eventColor){
            const rgbColor = hexToRgb(eventColor)

            task.fill = `rgba(${rgbColor.r},${rgbColor.g},${rgbColor.b}, 1)`
            task.eventTextColor = eventColor
          }
        })
    }

    const generatePersonalTasks = (task, azure_ad_id) => {
        task.start = task.start_date_time.substring(0,10)
        task.end = task.due_date_time.substring(0,10)
        task.type = 'project'
        task.owner = azure_ad_id
        const eventColor = store.state[USER_CALENDAR_APP_STORE_MODULE_NAME].calendarOptions.find(
          calendar => calendar.id === task.bucket,
        )
        if (eventColor !== undefined){
          const rgbColor = hexToRgb(eventColor.color)

          task.fill = `rgba(${rgbColor.r},${rgbColor.g},${rgbColor.b}, 1)`
          task.eventTextColor = eventColor.color
        }
    }




    const generateUserTasks = (user, disabled=false) => {
      var {name, azure_ad_id, supplier, personals, projects, id } = user
      
      if (azure_ad_id === undefined) azure_ad_id = id
      const member = {
        name,
        azure_ad_id,
        supplier
      }

      projects.forEach(project =>{
        generateProjectTasks(project, azure_ad_id)
      })

      personals.forEach(task => {
        generatePersonalTasks(task, azure_ad_id)
      })

      const {
        rows,
        items
      } = normalizeToGtsc(member, personals, projects, disabled)

      return {
        rows,
        items
      }
    }

    const timelineHandleTaskClick = taskObj => {
      store.commit('comp-alert/SET_MESSAGE', {})
      // Grab only event object
      event.value = taskObj

      // eslint-disable-next-line no-use-before-define
      isEventHandlerDrawerActive.value = true

    }

    //
    //* ——— Event Actions ——————————————————
    //

    const addTask = async (taskData) => {
      const token = await vm.$msal.getTokenPopup(foundryRequest)
      if (taskData.project) addProjectTask(taskData)
      else addPersonalTask(taskData)
    }

    const addProjectTask = (taskData) => {
      // Only get date from event
      /* eslint-disable no-param-reassign */
      if (taskData.start) taskData.start_date_time = new Date(taskData.start).toISOString()
      if (taskData.end) taskData.due_date_time = new Date(taskData.end).toISOString()

      /* eslint-enable */
      store.dispatch(`${USER_CALENDAR_APP_STORE_MODULE_NAME}/addProjectTask`, {task: taskData} ).then(() => {
        // TODO: Next Update - Perform adding or refetching
        if (taskData.calendar) {
          fetchUserTasks(taskData.calendar.start)
        } else {
          fetchUserTasks()
        }
      })
      .catch(error => {
          store.commit('comp-alert/SET_MESSAGE', error.response.data)
      })
    }

    const addPersonalTask = (taskData) => {

      // Only get date from event
      /* eslint-disable no-param-reassign */
      if (taskData.start) taskData.start_date_time = new Date(taskData.start).toISOString()
      if (taskData.end) taskData.due_date_time = new Date(taskData.end).toISOString()

      /* eslint-enable */
      store.dispatch(`${USER_CALENDAR_APP_STORE_MODULE_NAME}/addPersonalTask`, {task: taskData} ).then(() => {
        // TODO: Next Update - Perform adding or refetching
        if (taskData.calendar) {
          fetchUserTasks(taskData.calendar.start)
        } else {
          fetchUserTasks()
        }

      })
      .catch(error => {
          store.commit('comp-alert/SET_MESSAGE', error.response.data)
      })
    }

    const updateTask = async (taskData) => {
      const token = await vm.$msal.getTokenPopup(foundryRequest)
      if (taskData.project) updateProjectTask(taskData)
      else updatePersonalTask(taskData)
      
    }

    const updateProjectTask = (taskData) => {
      
      store.dispatch(`${USER_CALENDAR_APP_STORE_MODULE_NAME}/updateProjectTask`, {task: taskData} ).then(response => {
        updateTaskTimelineItem(response, taskData, 'study')
      })
    }

    const updatePersonalTask = (taskData) => {
      
      store.dispatch(`${USER_CALENDAR_APP_STORE_MODULE_NAME}/updatePersonalTask`, {task: taskData} ).then(response => {
        updateTaskTimelineItem(response, taskData, 'personal')
        
      })
      .catch(error => {
        console.log(error)
        if(error.response){
          store.commit('comp-alert/SET_MESSAGE', error.response.data)}
      })
    }

    const updateTaskTimelineItem = (response, taskData, model) => {
      const updatedTask = response.data
      // Only get date from event
      /* eslint-disable no-param-reassign */
        updatedTask.start = updatedTask.start_date_time
        updatedTask.end = updatedTask.due_date_time

        if (model === 'personal') {
          updatedTask.color = store.state[USER_CALENDAR_APP_STORE_MODULE_NAME].calendarOptions.find(
            calendar => calendar.id === updatedTask.bucket,
          ).color
        }

        const rgbColor = hexToRgb(updatedTask.color)

        updatedTask.eventTextColor = updatedTask.color

        updatedTask.fill = `rgba(${rgbColor.r},${rgbColor.g},${rgbColor.b}, 1)`

        updatedTask.class = taskData.class
        updatedTask.project = taskData.project
        updatedTask.type = taskData.type
        const items = calendarEvents.value.items
        const rows = JSON.parse(JSON.stringify(calendarEvents.value.rows))
        const assignments = updatedTask.assignments.reduce((acc,curr)=> (acc[curr]='',acc),{});
        
        const updatedTaskKeys = Object.keys(items).filter(e => {
          return items[e].taskId === updatedTask.id && items[e].model === model})

        // Update otherwise delete if user is removed from assignments
        updatedTaskKeys.forEach(key => {
          items[key] = convertTaskObjToGSTCObj(updatedTask, key, model, items[key].rowId, updatedTask.project)
          if (rows[items[key].rowId] && rows[items[key].rowId].azure_ad_id) {
            items[key].owner = rows[items[key].rowId].azure_ad_id
            if (rows[items[key].rowId].tasks){
              rows[items[key].rowId].tasks.map(task => {
                if(task.id === updatedTask.id) task = convertGSTCObjToTaskObj(items[key])
              })
            }
          }
          
          //If user already has item task then remove
          if (rows[items[key].rowId] !== undefined && rows[items[key].rowId].azure_ad_id){
            delete assignments[rows[items[key].rowId].azure_ad_id]
            // if (!items[key].assignments.includes(rows[items[key].rowId].azure_ad_id)){
            //   delete items[key]
            // }
          }
        })
        
        Object.keys(assignments).forEach(userId => {
          const itemId = generateTaskID(updatedTask.id, model.charAt(0), userId)
          const rowId = generateGSTCID(userId)
          items[itemId] = convertTaskObjToGSTCObj(updatedTask, itemId, model, rowId)
        })


        calendarEvents.value = {
          rows,
          items
        }
        //Close drawer
        isEventHandlerDrawerActive.value = false
    }

    const removeTask = (taskData) => {
      if (taskData.project) removeProjectTask()
      else removePersonalTask()
    }

    const removeProjectTask = () => {
      const taskId = event.value.id
      store.dispatch(`${USER_CALENDAR_APP_STORE_MODULE_NAME}/removeProjectTask`, { id: taskId }).then(() => {
        removeTaskTimelineItem(taskId, 'study')
      })
      .catch(error => {
        console.log(error)
        if(error.response){
          store.commit('comp-alert/SET_MESSAGE', error.response.data)
        }
      })
    }

    const removePersonalTask = () => {
      const taskId = event.value.id
      store.dispatch(`${USER_CALENDAR_APP_STORE_MODULE_NAME}/removePersonalTask`, { id: taskId }).then(() => {
        removeTaskTimelineItem(taskId, 'personal')
      })
      .catch(error => {
        console.log(error)
        if(error.response){
          store.commit('comp-alert/SET_MESSAGE', error.response.data)
        }
      })
    }

    const removeTaskTimelineItem = (taskId, model) => {
      const items = calendarEvents.value.items
      const rows = JSON.parse(JSON.stringify(calendarEvents.value.rows))

      const deletedTaskKeys = Object.keys(items).filter(e => {
        return items[e].taskId === taskId && items[e].model === model})

      deletedTaskKeys.forEach(key => {
        delete items[key]
        
      })

      calendarEvents.value = {
        rows,
        items
      }

      isEventHandlerDrawerActive.value = false
    }

    const onActiveTimelineViewChange = (item) => {
      activeTimelineView.value = item;
      timelineFilter.value = {
        timeline: item
      }
      refTimeline.value.updateTimeline(activeTimelineView)
    }
    const onActiveSortViewChange = (item) => {
      activeSortView.value = item;
      timelineFilter.value = {
        sort: item
      }
      refTimeline.value.updateSort(activeSortView)
    }

    const onActiveStatusViewChange = (item) => {
      activeStatusView.value = item;
      timelineFilter.value = {
        status: item
      }
      fetchUserTasks()
    }

    const onActiveCountriesViewChange = (item) => {
      activeCountriesView.value = item;
      timelineFilter.value = {
        countries: item
      }
      fetchUserTasks()
    }
    const onActiveResearchMethodsViewChange = (item) => {
      activeResearchMethView.value = item;
      timelineFilter.value = {
        researchMethods: item
      }
      fetchUserTasks()
    }
    const onActiveProjectTypesViewChange = (item) => {
      activeProjTypeView.value = item;
      timelineFilter.value = {
        projectTypes: item
      }
      fetchUserTasks()
    }

    const today = () => {
      refTimeline.value.today(activeTimelineView)
    }

    const next = () => {
      refTimeline.value.next(activeTimelineView)
    }

    const prev = () => {
      refTimeline.value.prev(activeTimelineView)
    }
    
    
    const fetchGroups = async () => {
      groupLoading.value = true
      const token = await vm.$msal.getTokenPopup(foundryRequest)
      
      store
        .dispatch('app-timeline/fetchGroups', {
        })
        .then(response => {
          const { data } = response
          groupOptions.value = formatOptions(data)
          // remove loading state
          groupLoading.value = false
          
        })
        .catch(error => {
          console.log(error)
        })
    }
    
    const fetchOffices = async () => {
      officeLoading.value = true
      const token = await vm.$msal.getTokenPopup(foundryRequest)
      
      store
        .dispatch('app-timeline/fetchOffices', {
        })
        .then(response => {
          const { data } = response
          officeOptions.value = formatOptions(data)
          // remove loading state
          officeLoading.value = false
          
        })
        .catch(error => {
          console.log(error)
        })
    }
    
    

    // watch(selectedCalendars, () => {
    //   fetchUserTasks()
    // })

    // ————————————————————————————————————
    //* ——— Event Handler
    // ————————————————————————————————————

    const isProjectDialogOpen = ref(false)
    const localProject = ref({})
    const openProjectDetailDialog = (project) => {
      isProjectDialogOpen.value = true

      localProject.value = JSON.parse(JSON.stringify(project))
    }

    const isEventHandlerDrawerActive = ref(false)

    onMounted(() => {
      
      fetchStatuses()
      fetchPersonalBuckets()
      fetchGroups()
      fetchOffices()
      
      fetchCountries()
      fetchProjectTypes()
      fetchResearchMethods()

      const user = JSON.parse(localStorage.getItem('user')) || {}
      if (router.currentRoute.name == 'user-timeline') router.replace({params: {id: user.email}}).catch(err => {})
      if (router.currentRoute.name == 'team-timeline') router.replace({params: {id: user.favorite_teams_group}}).catch(err => {})
      if (router.currentRoute.name == 'office-timeline') router.replace({params: {id: user.office}}).catch(err => {})
    })


    watch(isEventHandlerDrawerActive, val => {
      if (!val) clearEventData()
    })

    watch(() => props.params, (to, from) => {
      fetchUserTasks()
    })


    return {

      // Template Refs
      refTimeline,
      statusOptions,
      displayUserTasks,
      isProjectDialogOpen,
      localProject,
      openProjectDetailDialog,
      activeStatusView,
      activeCountriesView,
      activeResearchMethView,
      activeProjTypeView,
      statusLoading,
      statusSearch,
      resolveCountryStatusVariant,

      // Calendar View/Type
      activeTimelineView,
      timelineViewOptions,
      activeSortView,
      sortViewOptions,
      groupView,
      officeView,
      userView,
      fieldView,
      groupLoading,
      groupOptions,
      groupSearch,
      officeLoading,
      officeOptions,
      officeSearch,

      countryLoading,
      countryOptions,
      countrySearch,
      projTypeLoading,
      projTypeOptions,
      projTypeSearch,
      researchMetLoading,
      researchMetOptions,
      researchMetSearch,

      // Calendar Value & Events
      filterUserTasks,
      calendarValue,
      calendarEvents,
      event,
      clearEventData,
      onActiveTimelineViewChange,
      onActiveSortViewChange,
      onActiveStatusViewChange,
      onActiveCountriesViewChange,
      onActiveResearchMethodsViewChange,
      onActiveProjectTypesViewChange,
      timelineHandleTaskClick,

      t,
      
      addTask,
      updateTask,
      removeTask,
      fetchUserTasks,

      taskOptions,
      taskLoading,

      // Event handler sidebar
      isEventHandlerDrawerActive,
      next,
      prev,
      today,
      isLoaded,

      // Icons
      icons: {
        mdiChevronLeft,
        mdiChevronRight,
        mdiMenu,
        mdiMenuDown
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/calendar.scss';
</style>
