import store from '@/store'
import axios from '@axios'
import { transformRequestOptions } from '@core/utils'

export default {
  namespaced: true,
  state: {
    calendarOptions: [],
    selectedCalendars: [],
    taskColorOptions:[
      {
        name: 'Sapphire',
        color: '#0F2F71'  
      },
      {
        name: 'Cinnabar',
        color: '#E74C3C'  
      },
      {
        name: 'Dark Pastel Green',
        color: '#0EAC51'  
      },
      {
        name: 'Deep Cerise',
        color: '#DA3C78'  
      },
      {
        name: 'Viking',
        color: '#4CACC8'  
      },
      {
        name: 'Sun',
        color: '#F1892D'  
      },
      {
        name: 'Vivid Violet',
        color: '#7E349D'  
      },
      {
        name: 'Navy Blue',
        color: '#0077C0'  
      },
      {
        name: 'Persian Green',
        color: '#07ABA0'  
      },
      {
        name: 'Cotton Candy',
        color: '#FFBBD0'  
      },
      {
        name: 'Dandelion',
        color: '#FFD966'  
      },
    ]
  },
  getters: {},
  mutations: {
    SET_SELECTED_CALENDARS(state, val) {
      state.selectedCalendars = val
    },
    SET_CALENDARS(state, data){
      state.calendarOptions = data;
      state.selectedCalendars = data.map(item => item.id)
  }
  },
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/users/min/', {
              params: queryParams,
              paramsSerializer: params => transformRequestOptions(params)
            })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUserTasks(ctx, queryParams){
      const email = queryParams['email']
      delete queryParams['email']
      return new Promise((resolve, reject) => {
        axios
          .get(`/planners/users/${email}/?omit=project_tasks`, {
            params: queryParams,
            paramsSerializer: params => transformRequestOptions(params) 
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchUsersTasks(ctx, queryParams){
      return new Promise((resolve, reject) => {
        axios
          .get(`/planners/users/?omit=project_tasks`, {
            params: queryParams,
            paramsSerializer: params => transformRequestOptions(params) 
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchFieldsTasks(ctx, queryParams){
      return new Promise((resolve, reject) => {
        axios
          .get(`/planners/fields`, {
            params: queryParams,
            paramsSerializer: params => transformRequestOptions(params) 
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchOfficeTasks(ctx, queryParams){
      return new Promise((resolve, reject) => {
        axios
          .get(`/planners/offices/${queryParams.id}/`, {
            params: queryParams,
            paramsSerializer: params => transformRequestOptions(params) 
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPersonalBuckets(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/planners/personal_buckets/', {
              params: queryParams,
              paramsSerializer: params => transformRequestOptions(params)
            })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchProject(ctx, {id, ...queryParams}) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/planners/projects/${id}/`, {
              params: queryParams,
              paramsSerializer: params => transformRequestOptions(params)
            })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchProjectTasks(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/planners/projects_tasks/', {
              params: queryParams,
              paramsSerializer: params => transformRequestOptions(params)
            })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchEvents({ state }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/calendar/events', {
            params: {
              calendars: state.selectedCalendars.join(','),
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addPersonalTask(ctx,  { task }  ) {
      store.commit('app/LOADING', true)
      return new Promise((resolve, reject) => {
        axios
          .post('/planners/personals_tasks/', task )
          .then(response => {
            store.commit('app/LOADING', false)
            resolve(response)
          })
          .catch(error => {
            store.commit('app/LOADING', false)
            reject(error)
          })
      })
    },
    updatePersonalTask(ctx, { task } ) {
      store.commit('app/LOADING', true)
      return new Promise((resolve, reject) => {
        axios
          .patch(`/planners/personals_tasks/${task.id}/`, task )
          .then(response => {
            store.commit('app/LOADING', false)
            resolve(response)
          })
          .catch(error => {
            store.commit('app/LOADING', false)
            reject(error)
          })
      })
    },
    removePersonalTask(ctx, { id }) {
      store.commit('app/LOADING', true)
      return new Promise((resolve, reject) => {
        axios
          .delete(`/planners/personals_tasks/${id}/`)
          .then(response => {
            store.commit('app/LOADING', false)
            resolve(response)
          })
          .catch(error => {
            store.commit('app/LOADING', false)
            reject(error)
          })
      })
    },
    addProjectTask(ctx,  { task }  ) {
      store.commit('app/LOADING', true)
      return new Promise((resolve, reject) => {
        axios
          .post('/planners/projects_tasks/', task )
          .then(response => {
            store.commit('app/LOADING', false)
            resolve(response)
          })
          .catch(error => {
            store.commit('app/LOADING', false)
            reject(error)
          })
      })
    },
    updateProjectTask(ctx, { task } ) {
      store.commit('app/LOADING', true)
      return new Promise((resolve, reject) => {
        axios
          .patch(`/planners/projects_tasks/${task.id}/`, task )
          .then(response => {
            store.commit('app/LOADING', false)
            resolve(response)
          })
          .catch(error => {
            store.commit('app/LOADING', false)
            reject(error)
          })
      })
    },
    removeProjectTask(ctx, { id }) {
      store.commit('app/LOADING', true)
      return new Promise((resolve, reject) => {
        axios
          .delete(`/planners/projects_tasks/${id}/`)
          .then(response => {
            store.commit('app/LOADING', false)
            resolve(response)
          })
          .catch(error => {
            store.commit('app/LOADING', false)
            reject(error)
          })
      })
    },
    fetchGroups(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/users/teamsgroups/', {
              params: queryParams,
              paramsSerializer: params => transformRequestOptions(params),
              useCache: true
            })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchOffices(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/jobs/offices/', {
              params: queryParams,
              paramsSerializer: params => transformRequestOptions(params),
              useCache: true
            })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}

